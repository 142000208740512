import React, { FC } from 'react';
import { Button } from 'components/button/Button';
import { FeatureCard } from 'components/featureCard/FeatureCard';
import { home } from 'texts/texts';
import './AIUsage.css';

export const AIUsage: FC = () => {

  const handleWaitlistClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdxIO7hlJK8Ew9Y1ykensm5cg3-vy1ddFKLNjKkFFo9Ymt-Rg/viewform', '_blank');
  };

  const sparklingSrc = `${process.env.PUBLIC_URL}/assets/aiUsageSection-icons/sparkling.svg`;

  return (
    <div className="ai-usage-section">
      <div className="home-content-container">
        <div className="feature-container">
          <h2>{home.featureMessage}</h2>
          <div className='feature-row-1'>
            {home.features.map((feature, index) => (
              <React.Fragment key={index}>
                <FeatureCard
                  key={index}
                  iconSrc = {feature.iconSrc}
                  title={feature.title}
                  description={feature.description}
                />
                {index === 0 && (
                <>
                  <div className="additional-div"></div>
                  <div className="additional-div"></div>
                </>
              )}
                {index === 1 && (
                <>
                  <div className="additional-div"></div>
                  <div className="additional-div"></div>
                </>
              )}
              </React.Fragment>
            ))}
          </div>
          <div className="feature-row-2">
            <div className="horizontal-line"></div>
            <div className="vertical-line"></div>
          </div>
          <div id="cta-box-connect-container">
              <div className="additional-div"></div>
              <div className="additional-div"></div>
          </div>
          <div className="cta-box">
            <img src={sparklingSrc} alt="Sparkling Icon" />
            <h3>{home.featureConclusion}</h3>
            <Button label={home.buttonText} onClick={handleWaitlistClick} />
          </div>
        </div>
      </div>
    </div>
  )
};
