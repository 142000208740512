import React, { FC } from 'react';
import { Button } from 'components/button/Button';
import { Achievement } from 'components/achievement/Achievement';
import { home } from 'texts/texts';
import { TextWithStyle } from 'components/TextWithStyle';
import { renderTextWithLineBreaks } from 'utils/textUtils';
import './Hero.css';

export const Hero: FC = () => {

  const handleWaitlistClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdxIO7hlJK8Ew9Y1ykensm5cg3-vy1ddFKLNjKkFFo9Ymt-Rg/viewform', '_blank');
  };

  const handleAchievementClick = () => {
    window.open('https://www.linkedin.com/company/toastie/', '_blank');
  };

  const awsSrc = `${process.env.PUBLIC_URL}/assets/supporters/AWS-logo.png`;
  const buildersClubSrc = `${process.env.PUBLIC_URL}/assets/supporters/Builders-Club-logo.png`;
  const heroImageSrc = `${process.env.PUBLIC_URL}/assets/hero-image.svg`;
  const investmentNSWSrc = `${process.env.PUBLIC_URL}/assets/supporters/Investment-NSW-logo.png`;
  const nswGovSrc = `${process.env.PUBLIC_URL}/assets/supporters/NSW-Government-logo.png`;
  const pcosSrc = `${process.env.PUBLIC_URL}/assets/supporters/PCOS-logo.png`;
  const unswSrc = `${process.env.PUBLIC_URL}/assets/supporters/UNSW-logo.png`;

  return (
    <div className="hero-section">
      <div className="home-content-container">
        <section className='hero'>
          <div className='hero-text'>
            <h1>
              {home.welcomeMessage.map((part, index) => (
                <TextWithStyle key={index} text={part.text} style={part.style} />
              ))}
            </h1>
            <p>{renderTextWithLineBreaks(home.introduction, 'home-introduction')}</p>
            <Button label={home.buttonText} onClick={handleWaitlistClick} />
          </div>
          <div className='hero-image'>
            <img src={heroImageSrc} alt='Hero' />
          </div>
        </section>
        
        <section className='achievements'>
          {home.achievements.map((achievement, index) => (
            <Achievement
              key={index}
              title={achievement.title}
              description={achievement.description}
              onClick={handleAchievementClick}
            />
          ))}
        </section>

        <section className='supporters'>
          <p>{home.supporters}</p>
          <div>
            <img src={unswSrc} alt='UNSW' />
            <img src={nswGovSrc} alt='NSW government' />
            <img src={investmentNSWSrc} alt='Investment NSW' />
            <img src={awsSrc} alt='AWS' />
            <img src={buildersClubSrc} alt='The Builders Club' />
            <img src={pcosSrc} alt='PCOS Awareness Association' />
        </div>
        </section>
      </div>
    </div>
  )
};
