import React, { FC } from 'react';
import './Achievement.css';

interface AchievementProps {
  title: string;
  description: string;
  onClick: () => void;
}

export const Achievement: FC<AchievementProps> = ({ title, description, onClick }) => {
  return (
    <div className="achievement" onClick={onClick}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};