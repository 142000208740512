import React, { FC } from 'react';
import './TeamCard.css';

interface TeamCardProps {
  imgSrc: string;
  name: string;
  title: string;
  onClick: () => void;
}

export const TeamCard: FC<TeamCardProps> = ({ imgSrc, name, title, onClick }) => {
  return (
    <div className="team-card">
      <img src={imgSrc} alt={name} onClick={onClick}/>
      <h3>{name}</h3>
      <p>{title}</p>
    </div>
  );
};
