import React, { FC } from "react";
import { TextStyle } from '../texts/texts'

interface TextPart {
  text: string;
  style: TextStyle;
}
  
export const TextWithStyle: FC<TextPart> = ({ text, style }) => {
  let className: string;
  
  switch (style) {
    case TextStyle.Gradient:
      className = 'text-gradient';
      break;
    case TextStyle.Highlight:
      className = 'text-highlight';
      break;
    case TextStyle.Normal:
    default:
      className = 'text-normal';
  }

  return <span className={className}>{text}</span>;
};