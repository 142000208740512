import React, { FC } from 'react';
import { home } from 'texts/texts';
import { Button } from 'components/button/Button';
import { TextWithStyle } from 'components/TextWithStyle';
import './BottomSection.css';

export const BottomSection: FC = () => {

  const handleWaitlistClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdxIO7hlJK8Ew9Y1ykensm5cg3-vy1ddFKLNjKkFFo9Ymt-Rg/viewform', '_blank');
  };

  const logoSrc = `${process.env.PUBLIC_URL}/assets/toastie-logo.png`;
  const infoSrc = `${process.env.PUBLIC_URL}/assets/user-shield-icon.png`;

  return (
    <div className="bottom-section">
      <div className="home-content-container">
        <div className='container-vertical width-large'>
          <div className='container-vertical width-medium' id='slogan-div'>
            <img className='img-small' src={logoSrc} alt='Toastie Logo' />
            <h2>
              {home.slogan.map((part, index) => (
                <TextWithStyle key={index} text={part.text} style={part.style} />
              ))}
            </h2>
            <Button label={home.buttonText} onClick={handleWaitlistClick} />
          </div>
          <div id='info-container' className='container-vertical margin-large background-orange border-radius-25'>
            <img className='img-xsmall' src={infoSrc} alt='User Shield Icon' />
            <span className='span-bold'>{home.disclaimer.title}</span>
            <p>{home.disclaimer.description[0]}</p>
            <p>{home.disclaimer.description[1]}</p>
          </div>
        </div>
        <p id='ardorne'>Toastie is proudly developed and operated by Ardorne Pty Ltd</p>
      </div>
    </div>
  )
};
