import React, { useState } from 'react';
import { contact } from 'texts/texts';
import { renderTextWithLineBreaks } from 'utils/textUtils';
import './ContactForm.css';

interface FormState {
  email: string;
  userType: string;
  message: string;
  subscribe: boolean;
}

const ContactForm: React.FC = () => {

  const heroImageSrc = `${process.env.PUBLIC_URL}/assets/hero-image.svg`;

  const [formData, setFormData] = useState<FormState>({
    email: '',
    userType: '',
    message: '',
    subscribe: false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value;
    const name = target.name;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO [TOAS-239]: Handle form submissions.
    console.log(formData);

    setFormData({
      email: '',
      userType: '',
      message: '',
      subscribe: false
    });
  };

  return (
    <form onSubmit={handleSubmit} >
      <div id='form-section-1'>
        <div id='form-section-1-left'>
          <h1 className='text-gradient' id='contact-title'>{contact.title}</h1>
          <p id='contact-description'>{renderTextWithLineBreaks(contact.description, 'contact-description')}</p>
          <div className='label-input-pair'>
            <label>{contact.form.emailLabel}</label>
            <input 
              className='rounded-input-box'
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={contact.form.emailPlaceholder}
              required
            />
          </div>
          <div className='label-input-pair'>
            <label>{contact.form.descriptionLabel}</label>
            <select 
              name="userType" 
              value={formData.userType} 
              onChange={handleChange} 
              className='rounded-selection'>
              {contact.form.descriptionOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div id='hero-image'>
            <img src={heroImageSrc} alt='Hero' />
        </div>
      </div>
      <div className='label-input-pair'>
        <label id='message-label'>{contact.form.messageLabel}</label>
        <textarea
          className='rounded-textarea'
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder={contact.form.messagePlaceholder}
        />
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="subscribe"
            checked={formData.subscribe}
            onChange={handleChange}
          />
          {contact.form.subscribeCheckbox}
        </label>
      </div>
      <button className='button' id='submit-button' type="submit">{contact.form.submitButtonText}</button>
    </form>
  );
};

export default ContactForm;
