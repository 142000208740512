import React, { FC } from 'react';
import { ourStory } from 'texts/texts';
import { TextWithStyle } from 'components/TextWithStyle';
import { TeamCard } from 'components/teamCard/TeamCard'
import './OurStory.css';

export const OurStory: FC = () => {

  const handleTeamMemberClick = (link: string) => {
    window.open(link, '_blank');
  };

  function boldWord(text: string) {
    const parts = text.split(/(YEARS)/);
    return parts.map((part, index) => (
      part === "YEARS" ? <span key={index} style={{ fontWeight: 500 }}>{part}</span> : part
    ));
  }

  const ourStoryImageSrc = `${process.env.PUBLIC_URL}/assets/our-story-image.jpg`;

  return (
    <div className='home-content-container' id='our-story'>
      <div id="our-story-section">
        <h1>
          {ourStory.title.map((part, index) => (
            <TextWithStyle key={index} text={part.text} style={part.style} />
          ))}
        </h1>
        <div className='container-horizontal' id='our-story-content'>
          <div id='our-story-texts'>
            {ourStory.description.map((sentence, index) => (
              <p id='our-story-description' key={index}>{boldWord(sentence)}</p>
            ))}
          </div>
          <div id='our-story-img'>
            <img className='img-large border-radius-medium' src={ourStoryImageSrc} alt="Our Story" />
          </div>
        </div>
      </div>
      <div id="team-section">
        <h1>
          {ourStory.teamTitle.map((part, index) => (
            <TextWithStyle key={index} text={part.text} style={part.style} />
          ))}
        </h1>
        <p>{ourStory.teamMission}</p>
        <div>
          <div className='container-horizontal' id='team-cards'>
            {ourStory.teamMembers.slice(0, 2).map(member => (
              <TeamCard
              key={member.name}
              imgSrc={member.imgSrc}
              name={member.name}
              title={member.title}
              onClick={() => handleTeamMemberClick(member.linkedinUrl)}
            />
            ))}
          </div>
          <div className='container-horizontal' id='team-cards'>
            {ourStory.teamMembers.slice(2).map(member => (
              <TeamCard
              key={member.name}
              imgSrc={member.imgSrc}
              name={member.name}
              title={member.title}
              onClick={() => handleTeamMemberClick(member.linkedinUrl)}
            />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
