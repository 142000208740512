// Text style
export enum TextStyle {
  Normal = 'normal',
  Gradient = 'gradient',
  Highlight = 'highlight',
}

// Navbar
export const navbar = {
  nav: {
    contact: 'Contact us',
    story: 'Our Story',
    blog: 'Blog',
  },
  buttonText: 'Join waitlist',
};

// Footer
export const footer = {
  contact: 'Contact Us',
  copyright: '© 2024 Toastie',
  quote: 'Made with 💖 in Sydney, Australia',
  product: {
    title: 'Product',
    items: [
      'How it works',
      'Pricing',
      'Vision',
      'Impact'
    ],
  },
  resources: {
    title: 'Resources',
    items: [
      'Blog',
      'About the team',
      'Partner with us',
      'Contact us',
      'Careers (hiring)',
      'Privacy policy',
      'Terms of use'
    ],
  },
};

// Waitlist
export const waitlist = {
  title: [
    { text: 'Join the ', style: 'normal' as TextStyle },
    { text: 'waitlist.', style: 'gradient' as TextStyle },
  ],
  fields: {
    email: 'Email',
    device: {
      label: 'Device',
      options: ['Android', 'iOS', 'Web', 'Other'],
    },
    description: {
      label: 'What best describes you?',
      options: ['Chronic illness patient', 'GP/ Practitioner/ Specialist', 'Investor', 'Medical researcher', 'General human', 'Other'],
    },
  },
  buttonText: 'Sign up ✨',
  placeholder: {
    email: 'anna@toastie.au',
    device: 'Android',
    description: 'Patient',
  },
};

// Home page
export const home = {
  welcomeMessage: [
    { text: 'Your health journey, ', style: 'normal' as TextStyle },
    { text: 'simplified.', style: 'gradient' as TextStyle },
  ],
  introduction: 'Quick. Fun. Insightful.\nManage and track your chronic illnesses, and get actionable insights to take control of your health.',
  buttonText: 'Join our waitlist ✨',
  achievements: [
    { 
      title: '🏆 Winner',
      description: 'Startup Woman of the Year Award - OneRoof 2023'
    },
    {
      title: '🥇 1st Place',
      description: 'UNSW Founders New Wave Winner 2023'
    },
    {
      title: '🙋‍♀️ Top #5',
      description: 'People\'s Choice in AuNZ largest AI competition'
    },
  ],
  supporters: 'Supported by',
  // Home part 2
  featureMessage: 'Struggling to understand your health?',
  features: [
    {
      iconSrc: `${process.env.PUBLIC_URL}/assets/aiUsageSection-icons/ball-point-pen.svg`,
      title: 'Track',
      description: 'Spending too much time writing and tracking your symptoms, diet and lifestyle?'
    },
    {
      iconSrc: `${process.env.PUBLIC_URL}/assets/aiUsageSection-icons/bar-chart.svg`,
      title: 'Analytics',
      description: 'Unable to pin point why you are having these symptoms and why are they getting worse?'
    },
    {
      iconSrc: `${process.env.PUBLIC_URL}/assets/aiUsageSection-icons/library-books.svg`,
      title: 'Summary',
      description: 'Struggling to summarise your health in 5 minutes with your GPs / specialists?'
    },
    ],
  featureConclusion: 'Toastie uses AI to track, identify your triggers & help you understand your health',
  // Home part 3
  highlights: [
    {
      title: [
        { text: 'Ridiculously fast', style: 'highlight' as TextStyle },
        { text: ' and ', style: 'normal' as TextStyle },
        { text: 'easy', style: 'highlight' as TextStyle },
        { text: ' health tracking.', style: 'normal' as TextStyle },
      ],
      description: 'No more tedious, manual tracking. Simply take a photo of your meal, write in prose or use speech-to-text to track your day.'
    },
    {
      title: [
        { text: 'Make ', style: 'normal' as TextStyle },
        { text: 'data driven', style: 'highlight' as TextStyle },
        { text: ' decisions about your health.', style: 'normal' as TextStyle },
      ],
      description: 'Toastie delivers personalized care by identifying your triggers and providing actionable insights accompanied with our data-backed report.'
    },
    {
      title: [
        { text: 'Summarise', style: 'highlight' as TextStyle },
        { text: ' your health.', style: 'normal' as TextStyle },
      ],
      description: 'Show your doctors and specialists a data-backed accurate summary of your health.'
    },
  ],
  // Home FAQs
  faq: {
    title: [
      { text: 'Your questions, ', style: 'normal' as TextStyle },
      { text: 'answered.', style: 'highlight' as TextStyle },
    ],
    description: 'Have more questions? Get in touch with our team.',
    questions: [
      { 
        question: 'Is Toastie only for people with chronic illnesses?', 
        answer: 'No! Everyone can and is encouraged to use the Toastie app for health tracking. It is a useful tool for anybody who is looking to better understand and take control of their health!'
      },
      { 
        question: 'Where is my health data stored?', 
        answer: 'All data is encrypted and stored securely on our cloud servers if permission is granted by the user.' 
      },
      { 
        question: 'Will my health data be used or shared?', 
        answer: 'We take user privacy seriously. Toastie gives you full control over who sees your data and will never use or share your data without your permission. We adhere to strict privacy policies to ensure that your personal information remains secure and confidential.' 
      },
      { 
        question: 'Does Toastie use ChatGPT?', 
        answer: 'We use OpenAI\'s API to help analyze patterns in your health data. This allows us to provide personalized insights and recommendations, tailoring the app to your individual needs.' 
        },
      { 
        question: 'Is Toastie free to use?', 
        answer: 'Available on both Android and iOS, you can download the mobile app for FREE on Google Play or the App Store! Premium features are coming soon 👀' 
      },
      { 
        question: 'Can my GPs and doctors see my health data?', 
        answer: 'We do not send your data to your practitioner directly, however you are more than welcome to show them yourself! Keep an eye out for our future patient-practitioner integrated platform to optimize your doctor appointments 🌱✨' 
      },
    ],
  },
  // Home part 4
  slogan: [
    { text: 'We\'re ', style: 'normal' as TextStyle },
    { text: 'changing', style: 'gradient' as TextStyle },
    { text: ' the way you capture your health.', style: 'normal' as TextStyle },
  ],
  disclaimer: {
    title: 'Private | Secure | Encrypted',
    description: [
        'You have the right to keep your information private. Toastie gives you full control over who sees your data, and we make sure it stays safe and protected.',
        'We will never sell your information.'
    ],
  }
};

// Blog page
export const blog = {
  title: [
    { text: 'Read our ', style: 'normal' as TextStyle },
    { text: 'blog.', style: 'gradient' as TextStyle },
  ],
  description: 'Hear more about what the Toastie team has been up to! Get insights into our story, progress, and learnings ✏️',
  categories: [
    'View All', 
    'Health', 
    'Community Stories', 
    'Tech & Engineering', 
    'Company News'
  ],
  common: {
    readMoreText: 'Read more',
    shareOptions: 'Share to social media',
    keepReadingText: 'Keep reading',
    seeAllText: 'See all',
    authorsText: 'Authors: ',
  },
  posts: [
    {
      title: 'Demo night: UNSW Impact X Pre-accelerator',
      author: 'Lorem Ipsum',
      category: 'Tech & Engineering',
      readTime: '5 min read',
      summary: 'For the last three months, Toastie had the pleasure of participating in UNSW\'s first ever Impact X Pre-accelerator program for social impact startups.',
      content: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          // Paragraphs continue here
      ],
    },
    {
      title: 'How I got diagnosed with Ulcerative Colitis',
      author: 'Lorem Ipsum',
      category: 'Health',
      readTime: '5 min read',
      summary: 'For the last three months, Toastie had the pleasure of participating in UNSW\'s first ever Impact X Pre-accelerator program for social impact startups.',
      content: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
          // Paragraphs continue here
      ],
    },
  ],
};

// Contact page
export const contact = {
  title: 'Contact us.',
  description: 'Drop us a message below!\nWe\'ll get back to you as soon as we can.',
  form: {
    emailLabel: 'Your email *',
    emailPlaceholder: 'example@gmail.com',
    descriptionLabel: 'What best describes you?',
    descriptionOptions: ['Chronic illness patient', 'GP / Practitioner / Specialist', 'Investor', 'Medical researcher', 'General human', 'Other'],
    messageLabel: 'Message *',
    messagePlaceholder: 'Write your message here',
    subscribeCheckbox: 'Subscribe to our mailing list (if you\'re not already a member!)',
    submitButtonText: 'Send ✨',
  },
  socialMediaMessage: 'You can also find us on social media:',
};

// Our story page
export const ourStory = {
  title: [
    { text: 'Our ', style: 'normal' as TextStyle },
    { text: 'story.', style: 'gradient' as TextStyle },
  ],
  description: [
    'Hi 👋 We\'re Anna and Viv and we both struggle with chronic illnesses - ulcerative colitis (IBD) and polycystic ovarian syndrome (PCOS).',
    'Collectively, we\'ve spent YEARS manually tracking our health on spreadsheets and docs to manage our conditions and try to identify the root causes of our symptoms.',
    'As software engineers 👩‍💻, we\'re determined to make managing and understanding chronic illnesses easier for both ourselves as well as others.',
    'Toastie is a platform designed to make tracking and understanding your health quick, easy, and fun! It\'s all the tools we wish we had to help us on our health journeys, and completely redefines the way we manage our health.',
    'We\'re building live - join us on our journey! 🌱✨',
  ],
  teamTitle: [
    { text: 'Meet the ', style: 'normal' as TextStyle },
    { text: 'team.', style: 'gradient' as TextStyle },
  ],
  teamMission: 'Our mission is to help individuals with chronic illnesses take control of their health.',
  teamMembers: [
    {
      name: 'Annabel Zhou',
      title: 'Co-Founder',
      imgSrc: `${process.env.PUBLIC_URL}/assets/teamPhotos/Anna.png`,
      linkedinUrl: 'https://www.linkedin.com/in/annabelzh/',
    },
    {
      name: 'Viv Shen',
      title: 'Co-Founder',
      imgSrc: `${process.env.PUBLIC_URL}/assets/teamPhotos/Viv.png`,
      linkedinUrl: 'https://www.linkedin.com/in/vivshen/',
    },
    {
      name: 'Xiaoli Tang',
      title: 'Software Engineer',
      imgSrc: `${process.env.PUBLIC_URL}/assets/teamPhotos/Xiaoli.png`,
      linkedinUrl: 'https://www.linkedin.com/in/xiaolitang/',
    },
  ],
};
