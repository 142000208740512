import React, { FC } from 'react';
import ContactForm from './contactForm/ContactForm';
import SocialMedia from './socialMedia/SocialMedia';
import './ContactUs.css';

export const ContactUs: FC = () => {
  return (
    <div className='home-content-container' id='contact-us'>
      <ContactForm />
      <SocialMedia />
    </div>
  );
};
