import React, { FC } from 'react';
import { TextStyle } from 'texts/texts';
import { TextWithStyle } from 'components/TextWithStyle';
import './HighlightPair.css';

interface TextPart {
  text: string;
  style: TextStyle;
}

interface HighlightPairProps {
  title: TextPart[];
  description: string;
  imgSrc: string;
  imgAlt: string;
  reverse?: boolean;
}

export const HighlightPair: FC<HighlightPairProps> = ({ title, description, imgSrc, imgAlt, reverse = false }) => {
  return (
    <div className={`highlight-pair ${reverse ? 'reverse' : ''}`}>
      <div className="highlight-text">
        <h3>
          {title.map((part, index) => (
            <TextWithStyle key={index} text={part.text} style={part.style} />
          ))}
        </h3>
        <p>{description}</p>
      </div>
      <img src={imgSrc} alt={imgAlt} />
    </div>
  );
};