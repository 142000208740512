import React, { FC } from 'react';
import { HighlightPair } from 'components/highlightPair/HighlightPair';
import { home } from 'texts/texts';
import './Highlights.css';

export const Highlights: FC = () => {
  return (
    <div className="highlights-section">
      <div className="home-content-container">
        <div className='highlights-container'>
          {home.highlights.map((highlight, index) => (
            <HighlightPair
              key={index}
              title={highlight.title}
              description={highlight.description}
              imgSrc={`${process.env.PUBLIC_URL}/assets/highlightsSection/highlightImg${index + 1}.svg`}
              imgAlt={`Highlight image ${index + 1}`}
              reverse={index % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </div>
  )
};
