import React, { FC } from 'react';
import { Hero } from './heroSection/Hero';
import { AIUsage } from './AIUsageSection/AIUsage';
import { Highlights } from './highlightsSection/Highlights';
import { BottomSection } from './bottomCtaSection/BottomSection';
import './Home.css';

export const Home: FC = () => {
  return (
    <div className='home'>
      <Hero />
      <AIUsage />
      <Highlights />
      <BottomSection />
    </div>
  );
};
