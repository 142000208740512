import React from 'react';

export const renderTextWithLineBreaks = (text: string, keyBase: string) => {
  return text.split('\n').map((line, index, array) => (
    <React.Fragment key={`${keyBase}-${index}`}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
};
