import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import 'themes/colors.css';
import 'themes/grid.css';
import 'themes/fontStyles.css';
import { Navbar } from 'components/navbar/Navbar';
import { Home } from 'pages/home/Home';
import { ContactUs } from 'pages/contactUs/ContactUs';
import { OurStory } from 'pages/ourStory/OurStory';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ComingSoon from 'pages/comingSoon/ComingSoon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/blog" element={<ComingSoon />} />
      </Routes>
    </>
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
