import React, { FC } from 'react';
import './Button.css';

interface ButtonProps {
  label: String;
  onClick: () => void;
  className?: String;
}

export const Button: FC<ButtonProps> = ({ label, onClick, className }) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {label}
    </button>
  )
}