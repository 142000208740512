import React, { FC } from 'react';
import './FeatureCard.css';

interface FeatureCardProps {
  iconSrc: string;
  title: string;
  description: string;
}

export const FeatureCard: FC<FeatureCardProps> = ({ iconSrc, title, description }) => {
  return (
    <div className="feature-card">
      <div className="title-container">
        <img src={iconSrc} alt={title} />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
};